<template>
  <div>
    <el-row>
      <el-col :span="6" class="flex-row">
        <span class="span1">部门名称</span>
        <el-select style="width: 60%" v-model="searchParams.departmentId" placeholder="请选择" clearable>
          <el-option v-for="item in departmentsOpitons" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-row type="flex" style="margin-top: 12px" justify="end">
      <el-button type="primary" @click="exportExcel">导出</el-button>
    </el-row>
    <div style="height: 20px"></div>

    <el-table :data="tableData" border style="width: 100%" @cell-click="onCellClick">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="departmentName" label="部门名称"> </el-table-column>

      <el-table-column prop="productionStockCount" label="品种数量"> </el-table-column>

      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <!-- <el-popconfirm title="确认删除?" @confirm="toDelete(scope.row.id)">
            <el-button slot="reference" type="text" size="small">删除</el-button>
          </el-popconfirm> -->
          <el-button type="text" size="small" @click="toDetail(scope.row)">出入库详情</el-button>
          <el-button type="text" size="small" @click="toProductDetail(scope.row)">商品统计</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <stockDetail
      :allDic="allDic"
      :userOpitons="userOpitons"
      :departmentsOpitons="departmentsOpitons"
      :dialogVisible.sync="dialogVisible"
      :currentId="currentId"
    >
    </stockDetail>
    <productDetail
      :allDic="allDic"
      :userOpitons="userOpitons"
      :departmentsOpitons="departmentsOpitons"
      :dialogVisible.sync="dialogVisibleProduct"
      :currentId="currentId"
    >
    </productDetail>
  </div>
</template>

<script>
import { listProductionreport, exportStockHistory2 } from '@/api/stockManage3/api.js';
import stockDetail from './stockDetail.vue';
import dayjs from 'dayjs';
import axios from 'axios';

import productDetail from './productDetail.vue';
export default {
  components: {
    stockDetail,
    productDetail
  },
  props: {
    allDic: {},
    userOpitons: [],
    departmentsOpitons: []
  },
  data() {
    return {
      dialogVisibleProduct: false,
      dialogVisible: false,
      isEdit: false,
      expertName: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      questionType: '',
      searchParams: {
        departmentId: ''
      },
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      activeName: '1',

      tableData: [],
      options: []
    };
  },
  created() {},
  mounted() {
    this.getList();
    this.getDicList();
  },
  methods: {
    toProductDetail(item) {
      this.currentId = item.departmentId;
      this.dialogVisibleProduct = true;
    },
    exportExcel() {
      exportStockHistory2(this.searchParams)
        .then((res) => {})
        .catch((error) => {});
    },
    getType(key) {
      if (!this.allDic) {
        return;
      }
      return this.allDic['agriculture.resource.type'][key];
    },

    toDetail(item) {
      this.currentId = item.id;
      this.dialogVisible = true;
    },
    toEdit(item) {
      this.currentId = item.id;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    getDicList() {},
    getQuestionType(key) {
      return this.questionTypeDic[key];
    },
    onCellClick(row) {
      // this.$router.push({ name: 'detailService', query: { id: row.id, from: 'metting' } })
    },
    search() {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    toEdit(item) {
      this.currentId = item.id;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    handleClick(row) {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      listProductionreport({ pageNum: this.pageInfo.currentPage, pageSize: this.pageInfo.pageSize, ..._.pickBy({ ...this.searchParams }) })
        .then((res) => {
          this.tableData = res;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    confirmApply(e) {
      console.log(e);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
