<template>
  <el-dialog title="详情" :visible="dialogVisible" width="80%" @close="closeMe" destroy-on-close append-to-body :close-on-click-modal="false">
    <el-row v-if="!departmentId" style="margin-bottom: 12px">
      <el-col :span="6" class="flex-row-maring">
        <span class="span1">出入库</span>
        <el-select style="width: 60%" v-model="searchParams.businessType" placeholder="请选择" clearable>
          <el-option v-for="item in businessTypeList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex-row-maring">
        <span class="span1">部门名称</span>
        <el-select style="width: 60%" v-model="searchParams.departmentId" placeholder="请选择" clearable>
          <el-option v-for="item in departmentsOpitons" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex-row-maring">
        <span class="span1">操作人</span>
        <el-select style="width: 60%" v-model="searchParams.userId" placeholder="请选择" clearable>
          <el-option v-for="item in userOpitons" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <!-- <el-row type="flex" style="margin-top: 12px;" justify="end">
      <el-button type="primary" @click="exportExcel">导出</el-button>
    </el-row> -->
    <el-table v-if="dialogVisible" ref="multipleTable" :data="tableData" border style="width: 100%" height="50vh">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="updateTime" label="时间"> </el-table-column>
      <el-table-column prop="agriculturalProductionName" label="品种"></el-table-column>
      <el-table-column prop="address" label="出入库">
        <template slot-scope="scope">
          {{ scope.row.businessType == 1 ? '入库' : '出库' }}
        </template>
      </el-table-column>
      <el-table-column prop="unitSpecs" label="规格"> </el-table-column>
      <el-table-column prop="amount" label="件数"> </el-table-column>
      <el-table-column prop="descriptions" label="备注"> </el-table-column>
      <el-table-column prop="departmentName" label="所属部门"> </el-table-column>
      <el-table-column prop="userName" label="操作人"> </el-table-column>
    </el-table>
    <div>
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
  </el-dialog>
</template>
<script>
import { recordList, startPlantingWithScheme, exportStockHistory } from '@/api/stockManage3/api.js';
export default {
  props: {
    dialogVisible: false,
    currentId: '',
    departmentId: '',
    allDic: {},
    userOpitons: [],
    departmentsOpitons: []
  },
  components: {},
  computed: {},
  data() {
    return {
      tableData: [],
      searchParams: {
        userId: '',
        departmentId: '',
        businessType: ''
      },
      multipleSelection: [],
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      options: [],
      businessTypeList: [
        {
          id: '',
          name: '全部'
        },
        {
          id: 1,
          name: '入库'
        },
        {
          id: 2,
          name: '出库'
        }
      ]
    };
  },
  created() {},
  destroyed() {},
  mounted() {
    this.getDicList();
  },
  watch: {
    dialogVisible(val) {
      this.multipleSelection = [];
      this.pageInfo = {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      };
      this.searchParams = {
        userId: '',
        departmentId: '',
        businessType: ''
      };

      if (val) {
        this.getDicList();
        this.getList();
      }
    }
  },
  methods: {
    exportExcel() {
      exportStockHistory(this.searchParams)
        .then((res) => {})
        .catch((error) => {});
    },
    getDicList() {},
    getType(key) {
      if (!this.allDic) {
        return;
      }
      return this.allDic['agriculture.resource.type'][key];
    },

    onSubmit() {
      if (!this.multipleSelection.length) {
        this.$message('请选择地块');
        return;
      }
      let ids = this.multipleSelection.map((e) => {
        return e.id;
      });
      let formData = new FormData();
      formData.append('schemeId', this.$route.query.id);
      formData.append('landIds', ids);
      startPlantingWithScheme(formData)
        .then((res) => {
          this.$message({
            message: '创建成功',
            type: 'success'
          });
          this.closeMe();
          this.updateList();
        })
        .catch((error) => {});
      console.log('');
    },
    updateList() {
      this.$emit('updateList');
    },
    closeMe() {
      this.$emit('update:dialogVisible', false);
    },
    search() {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getRowKey(row) {
      return row.id;
    },
    handleSelectionChange(val) {
      console.log('@选中的值', val);
      this.multipleSelection = val;
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    getList() {
      if (this.departmentId) {
        this.searchParams.departmentId = this.departmentId;
      }
      recordList({
        agriculturalProductionId: this.currentId,
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ..._.pickBy({ ...this.searchParams })
      }).then((res) => {
        this.tableData = res.rows;
        this.pageInfo.totalPage = res.total;
      });
    }
  }
};
</script>
<style scoped lang="scss">
.flex-center {
  height: 40px;
  line-height: 40px;
}
</style>
